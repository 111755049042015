import React from "react"
import styled from "@emotion/styled"
import theme from "../theme/theme"

const {
  mq: { small },
} = theme

const StyledLargeLogo = styled.div`
  position: absolute;
  ${small} {
    width: 80%;
  }
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  opacity: 1;
  transition: all ${({ theme }) => theme.animation.duration} ease;

  .st0 {
    fill: #155147;
  }
  .c-shape {
    enable-background: new;
  }
  .st2 {
    fill: #8b7262;
  }
  .st3 {
    fill: #96a08f;
  }
  .st4 {
    fill: #ce917e;
  }
  .st5 {
    fill: #cfb7ab;
  }
  .st6 {
    fill: #ce917e;
  }
  .st7 {
    fill: #ddd6d2;
  }
  .st8 {
    fill: #454e4c;
  }

  /* characters */
  .logo-char {
    fill: white;
    stroke-width: 3;
    stroke-opacity: 0.5;
    stroke: #bf8651;
    animation-name: fadeIn;
  }

  #char-1 {
    animation-delay: 550ms;
  }
  #char-2 {
    animation-delay: 575ms;
  }
  #char-3 {
    animation-delay: 600ms;
  }
  #char-4 {
    animation-delay: 625ms;
  }
  #char-5 {
    animation-delay: 650ms;
  }
  #char-6 {
    animation-delay: 675ms;
  }
  #char-7 {
    animation-delay: 700ms;
  }
  #char-8 {
    animation-delay: 725ms;
  }
  #char-9 {
    animation-delay: 750ms;
  }
  #char-10 {
    animation-delay: 775ms;
  }
  #char-11 {
    animation-delay: 800ms;
  }
  #char-12 {
    animation-delay: 825ms;
  }

  /* shapes */
  .c-shape {
    animation-name: fadeInLeft;
  }

  #c-shape-1 {
    animation-delay: 25ms;
  }
  #c-shape-2 {
    animation-delay: 50ms;
  }
  #c-shape-3 {
    animation-delay: 75ms;
  }
  #c-shape-4 {
    animation-delay: 100ms;
  }
  #c-shape-5 {
    animation-delay: 125ms;
  }
  #c-shape-6 {
    animation-delay: 150ms;
  }
  #c-shape-7 {
    animation-delay: 175ms;
  }
  #c-shape-8 {
    animation-delay: 200ms;
  }
`

const LargeLogo = () => {
  return (
    <StyledLargeLogo>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlinkHref="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 -2 520.622 50"
        enableBackground="new 0 -2 520.622 50"
        xmlSpace="preserve"
      >
        <g id="Layer_1">
          <g className="c-shape animated" id="c-shape-1">
            <g>
              <polygon
                className="st7"
                points="43.043,0.421 43.978,0.306 58.107,10.573 57.171,10.688 	"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="57.359,11.302 58.293,11.187 58.832,41.335 57.896,41.451 	"
              />
            </g>
            <g>
              <path
                className="st7"
                d="M57.171,10.688l0.936-0.115c0.099,0.072,0.183,0.346,0.187,0.614l-0.935,0.115
			C57.354,11.034,57.27,10.76,57.171,10.688z"
              />
            </g>
            <g>
              <path
                className="st7"
                d="M57.171,10.688c0.099,0.072,0.183,0.346,0.188,0.614l0.537,30.148l-0.354-0.258l-0.529-29.665
			L43.061,1.388l-0.017-0.967L57.171,10.688z"
              />
            </g>
          </g>

          <g className="c-shape animated" id="c-shape-2">
            <g>
              <polygon
                className="st5"
                points="36.894,0.421 37.829,0.306 51.96,10.575 51.026,10.69 	"
              />
            </g>
            <g>
              <polygon
                className="st5"
                points="51.209,11.302 52.145,11.187 52.682,41.335 51.748,41.451 	"
              />
            </g>
            <g>
              <path
                className="st5"
                d="M51.026,10.69l0.935-0.115c0.096,0.07,0.18,0.344,0.185,0.612l-0.936,0.115
			C51.205,11.034,51.122,10.76,51.026,10.69z"
              />
            </g>
            <g>
              <path
                className="st5"
                d="M51.026,10.69c0.096,0.07,0.179,0.344,0.184,0.612l0.538,30.148l-0.354-0.258l-0.529-29.665
			L36.912,1.388l-0.018-0.967L51.026,10.69z"
              />
            </g>
          </g>

          <g className="c-shape animated" id="c-shape-3">
            <g>
              <polygon
                className="st3"
                points="30.745,0.421 31.68,0.306 45.812,10.576 44.876,10.691 	"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="45.061,11.302 45.996,11.187 46.534,41.335 45.598,41.451 	"
              />
            </g>
            <g>
              <path
                className="st3"
                d="M44.876,10.691l0.936-0.115c0.095,0.069,0.179,0.343,0.184,0.611l-0.935,0.115
			C45.056,11.034,44.972,10.76,44.876,10.691z"
              />
            </g>
            <g>
              <path
                className="st3"
                d="M44.876,10.691c0.096,0.069,0.18,0.343,0.185,0.611l0.537,30.148l-0.354-0.257l-0.529-29.666
			L30.762,1.388l-0.017-0.967L44.876,10.691z"
              />
            </g>
          </g>

          <g className="c-shape animated" id="c-shape-4">
            <g>
              <polygon
                className="st8"
                points="24.596,0.421 25.531,0.306 39.663,10.576 38.728,10.691 	"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="38.912,11.302 39.846,11.187 40.384,41.335 39.449,41.451 	"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M38.728,10.691l0.935-0.115c0.096,0.069,0.18,0.343,0.184,0.611l-0.935,0.115
			C38.907,11.034,38.824,10.76,38.728,10.691z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M38.728,10.691c0.096,0.069,0.179,0.343,0.184,0.611l0.537,30.148l-0.354-0.258l-0.529-29.665
			L24.613,1.388l-0.017-0.967L38.728,10.691z"
              />
            </g>
          </g>

          <g className="c-shape animated" id="c-shape-5">
            <g>
              <polygon
                className="st2"
                points="18.447,0.421 19.382,0.306 33.513,10.576 32.579,10.691 	"
              />
            </g>
            <g>
              <polygon
                className="st2"
                points="32.763,11.302 33.697,11.187 34.235,41.335 33.3,41.451 	"
              />
            </g>
            <g>
              <path
                className="st2"
                d="M32.579,10.691l0.935-0.115c0.096,0.069,0.179,0.343,0.184,0.611l-0.935,0.115
			C32.758,11.034,32.674,10.76,32.579,10.691z"
              />
            </g>
            <g>
              <path
                className="st2"
                d="M32.579,10.691c0.096,0.069,0.179,0.343,0.184,0.611L33.3,41.451l-0.354-0.258l-0.529-29.665
			L18.464,1.388l-0.018-0.967L32.579,10.691z"
              />
            </g>
          </g>

          <g className="c-shape animated" id="c-shape-6">
            <g>
              <polygon
                className="st4"
                points="12.298,0.421 13.233,0.306 27.365,10.576 26.43,10.691 	"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="26.614,11.302 27.549,11.187 28.086,41.335 27.151,41.451 	"
              />
            </g>
            <g>
              <path
                className="st4"
                d="M26.43,10.691l0.935-0.115c0.095,0.069,0.179,0.343,0.184,0.611l-0.935,0.115
			C26.609,11.034,26.525,10.76,26.43,10.691z"
              />
            </g>
            <g>
              <path
                className="st4"
                d="M26.43,10.691c0.096,0.069,0.179,0.343,0.184,0.611l0.538,30.148l-0.354-0.258l-0.529-29.665
			L12.315,1.388l-0.017-0.967L26.43,10.691z"
              />
            </g>
          </g>

          <g className="c-shape animated" id="c-shape-7">
            <g>
              <polygon
                className="st0"
                points="6.149,0.421 7.084,0.306 21.215,10.575 20.28,10.69 	"
              />
            </g>
            <g>
              <polygon
                className="st0"
                points="20.465,11.302 21.399,11.187 21.937,41.335 21.002,41.451 	"
              />
            </g>
            <g>
              <path
                className="st0"
                d="M20.28,10.69l0.935-0.115c0.096,0.07,0.179,0.344,0.184,0.612l-0.935,0.115
			C20.46,11.034,20.376,10.76,20.28,10.69z"
              />
            </g>
            <g>
              <path
                className="st0"
                d="M20.28,10.69c0.096,0.07,0.18,0.344,0.185,0.612l0.537,30.148l-0.354-0.257l-0.529-29.666L6.167,1.388
			L6.149,0.421L20.28,10.69z"
              />
            </g>
          </g>

          <g className="c-shape animated" id="c-shape-8">
            <g>
              <polygon
                className="st6"
                points="0,0.421 0.935,0.306 15.066,10.576 14.132,10.691 	"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="14.316,11.302 15.25,11.187 15.788,41.335 14.853,41.451 	"
              />
            </g>
            <g>
              <path
                className="st6"
                d="M14.132,10.691l0.935-0.115c0.099,0.072,0.179,0.343,0.184,0.611l-0.935,0.115
			C14.311,11.034,14.23,10.763,14.132,10.691z"
              />
            </g>
            <g>
              <path
                className="st6"
                d="M14.132,10.691c0.099,0.072,0.179,0.343,0.184,0.611l0.537,30.148l-0.354-0.258L13.97,11.528
			L0.018,1.388L0,0.421L14.132,10.691z"
              />
            </g>
          </g>
        </g>

        <g id="Layer_2">
          <path
            className="logo-char animated"
            id="char-1"
            d="M90.116,41.425V0.29h16.744c6.721,0,11.587,6.315,11.587,12.457c0,6.431-4.519,12.515-11.182,12.515H91.274v16.165H90.116
		z M91.274,24.102h15.991c6.257,0,10.023-5.794,10.023-11.356c0-5.852-4.635-11.298-10.429-11.298H91.274V24.102z"
          />

          <path
            className="logo-char animated"
            id="char-2"
            d="M123.427,41.425V0.29h1.159v39.977h25.84v1.159H123.427z"
          />

          <path
            className="logo-char animated"
            id="char-3"
            d="M152.974,0.29l15.875,23.87l15.934-23.87h1.332l-16.686,25.203v15.933h-1.159V25.376L151.642,0.29H152.974z"
          />

          <path
            className="logo-char animated"
            id="char-4"
            d="M227.653,0.058c8.517,0,13.094,3.766,15.701,8.98l-1.102,0.348c-3.128-5.794-8.227-8.169-14.6-8.169
		c-10.835,0-17.556,10.023-17.556,19.699c0,10.313,8.111,19.698,18.251,19.698c5.04,0,13.035-3.766,14.89-8.69l1.101,0.29
		c-2.201,6.025-10.197,9.56-15.99,9.56c-11.414,0-19.409-10.718-19.409-20.857C208.939,10.197,216.703,0.058,227.653,0.058z"
          />

          <path
            className="logo-char animated"
            id="char-5"
            d="M249.839,20.857C249.839,9.907,258.183,0,269.017,0c11.355,0,19.119,10.603,19.119,20.857
		c0,11.008-8.343,20.684-19.119,20.858C257.662,41.368,249.839,31.402,249.839,20.857z M269.017,40.556
		c10.719,0,17.961-10.023,17.961-19.699c0-10.255-7.764-19.699-17.961-19.699c-10.719,0-18.019,9.965-18.019,19.699
		C251.289,31.112,258.588,40.556,269.017,40.556z"
          />

          <path
            className="logo-char animated"
            id="char-6"
            d="M292.653,41.425L310.614,0.29h1.043l17.902,41.136h-1.274l-6.431-14.716h-21.495l-6.432,14.716H292.653z M311.135,1.97
		L300.822,25.55h20.511L311.135,1.97z"
          />

          <path
            className="logo-char animated"
            id="char-7"
            d="M335.003,34.589c3.766,3.881,9.153,5.967,14.426,5.967c6.315,0,14.021-2.607,14.021-9.733
		c0-7.879-8.17-9.386-14.311-9.386c-7.475,0-14.427-2.492-14.427-10.834C334.713,2.665,342.477,0,348.966,0
		c6.778,0,10.196,2.028,12.804,5.736l-1.101,0.463c-2.607-3.94-7.416-5.041-11.703-5.041c-6.432,0-12.92,2.433-13.094,9.444
		c0,7.879,6.895,9.675,13.268,9.675c7.416,0,15.469,2.144,15.469,10.545c0,8.111-8.69,10.893-15.18,10.893
		c-6.778,0-11.819-2.897-15.295-6.374L335.003,34.589z"
          />

          <path
            className="logo-char animated"
            id="char-8"
            d="M400.064,1.448h-15.759v39.977h-1.158V1.448h-15.76V0.29h32.677V1.448z"
          />

          <path
            className="logo-char animated"
            id="char-9"
            d="M431.639,40.266v1.159H404.64V0.29h26.478v1.159h-25.319v18.366h22.19v1.159h-22.19v19.293H431.639z"
          />

          <path
            className="logo-char animated"
            id="char-10"
            d="M436.213,41.425V0.29h16.744c6.721,0,11.587,6.315,11.587,12.457c0,6.373-4.287,12.051-10.486,12.515l10.429,16.165
		h-1.391l-10.429-16.165h-15.296v16.165H436.213z M437.372,24.102h15.991c6.199,0,10.023-5.678,10.023-11.356
		c0-5.852-4.635-11.298-10.429-11.298h-15.586V24.102z"
          />

          <path
            className="logo-char animated"
            id="char-11"
            d="M472.016,34.589c3.766,3.881,9.153,5.967,14.426,5.967c6.315,0,14.021-2.607,14.021-9.733
		c0-7.879-8.17-9.386-14.311-9.386c-7.475,0-14.427-2.492-14.427-10.834C471.726,2.665,479.49,0,485.979,0
		c6.778,0,10.196,2.028,12.804,5.736l-1.101,0.463c-2.607-3.94-7.416-5.041-11.703-5.041c-6.432,0-12.92,2.433-13.094,9.444
		c0,7.879,6.895,9.675,13.268,9.675c7.416,0,15.469,2.144,15.469,10.545c0,8.111-8.69,10.893-15.18,10.893
		c-6.778,0-11.819-2.897-15.295-6.374L472.016,34.589z"
          />
        </g>
      </svg>
    </StyledLargeLogo>
  )
}

export default LargeLogo
